import React, { useContext, useState } from 'react';
import VisibilitySensor from '../components/VisibilitySensor';
import Layout from '../components/Layout';
import MainImage from '../components/MainImage';
import Collection from '../components/Collection';
import AboutUs from '../components/AboutUs';
import SEO from '../components/seo';
import Contact from '../components/Contact';
import AppContext from '../components//AppContext';

const IndexPage = () => {
   const [isInitialized, setInitialized] = useState(false);

   return (
      <Layout>
         <SEO title="Moskitom Kraków - moskitiery, rolety, okna, drzwi" image="https://www.moskitom.pl/moskifb.jpg" />
         <MyVisibilitySensor isInitialized={isInitialized} setInitialized={setInitialized} path="/#start">
            <MainImage />
         </MyVisibilitySensor>
         <MyVisibilitySensor isInitialized={isInitialized} setInitialized={setInitialized} path="/#o-nas">
            <AboutUs />
         </MyVisibilitySensor>

         <MyVisibilitySensor isInitialized={isInitialized} setInitialized={setInitialized} path="/#produkty">
            <Collection />
         </MyVisibilitySensor>

         <MyVisibilitySensor isInitialized={isInitialized} setInitialized={setInitialized} path="/#kontakt">
            <Contact />
         </MyVisibilitySensor>
      </Layout>
   );
};

const mainTopValue = typeof window === 'undefined' ? 200 : Math.round(window.innerHeight / 2);

const MyVisibilitySensor = (
   { children, path, isInitialized, setInitialized, ...props } // eslint-disable-line
) => {
   const { setActiveMenuItem } = useContext(AppContext);

   const setActive = goToPath => {
      if (isInitialized) {
         setActiveMenuItem(goToPath);
      }
      setInitialized(true);
   };

   return (
      <VisibilitySensor
         scrollCheck
         scrollDelay={50}
         intervalCheck={false}
         minTopValue={mainTopValue}
         onChange={isVisible => isVisible && setActive(path)}
         offset={{ top: 200 }}
         {...props}
      >
         <div>{children}</div>
      </VisibilitySensor>
   );
};

export default IndexPage;
