export default [
   {
      id: 'moskitiera plisowa',
      alt: 'moskitiera plisowa',
      header: 'moskitiery',
      desc:
         'Moskitiery są doskonałym i praktycznym rozwiązaniem, które stanowi skuteczną ochronę przeciw komarom i innym owadom, a także przed pyłkami znajdującymi się w powietrzu oraz smogiem. W swojej ofercie posiadamy: moskitiery okienne ramkowe, moskitiery drzwiowe na zawiasy (z opcją montażu klapy wyjścia dla zwierząt), moskitiery rolowane pionowe, moskitiery na okna dachowe, moskitiery plisowane. Dostępne rodzaje siatek do moskitier: siatka z włókna szklanego w kolorze popielatym lub czarnym, siatka aluminiowa, siatka stalowa, siatka antyalergiczna, siatka antysmogowa.',
   },
   {
      id: 'zaluzje aluminiowe',
      alt: 'żaluzje aluminowe',
      header: 'żaluzje aluminiowe',
      desc:
         'Żaluzje aluminiowe polecane są do wszystkich typów pomieszczeń. Sterowanie może odbywać się są za pomocą pokrętła i sznurka oraz elektrycznie. Mocowanie żaluzji jest proste i łatwe do demontażu co umożliwia sprawne czyszczenie i konserwację. Odpowiednio wyprofilowane lamele skutecznie odbijają promienie słoneczne, dzięki którym pomieszczenie jest zaciemnione i nie nagrzewa się. Oferujemy trzy rodzaje szerokości listewek: 16, 25, 50 mm.',
   },
   {
      id: 'zaluzje drewniane',
      alt: 'żaluzje drewniane-bambusowe',
      header: 'żaluzje drewniane i bambusowe',
      desc:
         'Żaluzje drewniane mają piękne stylowe wykończenia wykonane z naturalnego ekologicznego drewna, dzięki temu idealnie komponują się w stylowych i eleganckich wnętrzach. Dodatkowym atutem jest fakt, że doskonale nadają się do czyszczenia i konserwacji. Żaluzje drewniane są produktem pochodzenia naturalnego, więc standardowym zjawiskiem może być występowanie słojów i rozbieżność odcieni co jeszcze bardziej podkreśla ich oryginalność. W ofercie znajdują się także ciekawe żaluzje wykonane ze specjalnie prasowanego bambusa. W kolekcji żaluzje występują w trzech szerokościach lameli: 25, 50, 65 mm. Standardowo obsługa jest manualna (sznurek i pokrętło), natomiast na duże powierzchnie zalecane są napędy elektryczne.',
   },
   {
      id: 'zaluzje plisowane',
      alt: 'żaluzje plisowane',
      header: 'żaluzje plisowane',
      desc:
         'Plisy to system osłon okiennych, który skutecznie chroni pomieszczenia przed nadmiernym nasłonecznieniem. To rodzaj dekoracyjnej i eleganckiej zasłony przeciwsłonecznej pozwalającej uzyskać niepowtarzalne efekty świetlne i możliwości kompozycyjne. W plisach mamy możliwość zaciągania żaluzji zarówno od dołu jak i od góry. Stosowane są do różnych typów okien: standardowych, trójkątnych, trapezowych, okrągłych, dachowych. Tkaniny w plisach tworzą regularne i harmonijne fałdy w bardzo bogatej gamie kolorystycznej. Sterowanie odbywa się za pomocą uchwytu lub sznurka.',
   },
   {
      id: 'zaluzje pionowe',
      alt: 'żaluzje pionowe',
      header: 'żaluzje pionowe',
      desc:
         'Żaluzje pionowe (inaczej vertical) dekorują i jednocześnie zabezpieczają przed promieniami słonecznymi. Zaletą tego typu systemu jest to, iż stanowią doskonałe połączenie żaluzji i firan. Duży wybór tkanin pozwala na dopasowanie wzoru i koloru do każdego wnętrza tworząc przyjemne warunki do pracy i wypoczynku. Stosowane są najczęściej w biurach, bankach, urzędach, hotelach, a także mieszkaniach. Standardowo wykonujemy verticale o szerokości lameli 89 i 127 mm. Oferujemy obsługę manualną oraz elektryczną.',
   },
   {
      id: 'rolety materialowe',
      alt: 'rolety materiałowe',
      header: 'rolety materiałowe',
      desc:
         'Funkcjonalne, estetyczne i praktyczne rolety wewnętrzne, nadają się do wszystkich typów okien. Pełnią funkcję nie tylko jako ochrona przed promieniami słonecznymi, ale również jako dekoracja wnętrz. Sterowanie może odbywać się za pomocą łańcuszka lub elektrycznie. Rolety możemy zatrzymać wkażdej pozycji bez konieczności mocowania dodatkowego zaczepu (hamulec w mechanizmie). Szeroka kolekcja tkanin i kolorów, umożliwia dopasowanie rolet do każdego wnętrza. Oferujemy: rolety w kasecie z prowadnicami, rolety wolnowiszące mini, rolety wolnowiszące standard, rolety wolnowiszące standard w kasecie.',
   },
   {
      id: 'rolety noc dzien',
      alt: 'rolety noc-dzień',
      header: 'rolety noc-dzień',
      desc:
         'Rolety noc-dzień, to jeden z najnowszych systemów osłon okiennych. To nowoczesna dekoracja łącząca funkcję żaluzji, rolety, firany i zasłony. Materiał w rolecie wykonany jest z poziomych pasów transparentnych oraz zaciemniających, dzięki którym można regulować ilość wpadającego światła według własnych potrzeb, stwarzając przy tym niepowtarzalny klimat w pomieszczeniu. Rolety noc-dzień dostępne są w różnych modelach: rolety noc-dzień wolnowiszące mini, rolety noc-dzień wolnowiszące standard, rolety noc-dzień wolnowiszące w kasecie, rolety noc-dzień w kasecie z prowadnicami.',
   },
   {
      id: 'rolety dachowe',
      alt: 'rolety dachowe',
      header: 'rolety dachowe',
      desc:
         'Rolety tego typu cieszą się bardzo dużą popularnością i niezawodnym funkcjonowaniem. Stanowią bardzo dobrą ochronę przed światłem, zapewniając doskonałe zaciemnienie pomieszczenia. Dodatkowym atutem są tkaniny z powłoką pearl, która pełni funkcję odbijania promieni słonecznych. Zastosowanie do okien typu: Fakro, Velux, Roto, Roto Alpine, Okpol. Dostępne modele: rolety dachowe standard, rolety dachowe w kasetach z prowadnicami.',
   },
   {
      id: 'rolety rzymskie',
      alt: 'rolety rzymskie',
      header: 'rolety rzymskie classic',
      desc:
         'Rolety rzymskie, to zasłony podnoszone przy pomocy mechanizmu sterowanego łańcuchem lub elektrycznie za pomocą pilota. Nowoczesność, elegancja i styl, to cechy charakterystyczne dla tych rolet nadających każdemu wnętrzu niepowtarzalnego wyglądu i klimatu. Dużą zaletą rolet rzymskich są tkaniny, które można prać i prasować. Rolety rzymskie dzielimy na cztery modele z różnymi szyciami fiszbin.',
   },
   {
      id: 'rolety rzymskie soft line',
      alt: 'rolety rzymskie soft line',
      header: 'rolety rzymskie softline',
      desc:
         'Rolety rzymskie softline, to połączenie mechanizmu żaluzji plisowanej z roletą rzymską. Ten system cieszy się dużą popularnością i niezawodnym funkcjonowaniem. Montaż odbywa się we wnęce szybowej bez ograniczania otwierania okna. Zastosowany mechanizm umożliwia regulację wpadającego światła w dwie strony, czyli góra-dół. Rolety rzymskie softline występują w czterech modelach z różnymi szyciami fiszbin.',
   },
   {
      id: 'foto rolety',
      alt: 'foto rolety',
      header: 'fotorolety',
      desc:
         'Fotorolety (rolety z nadrukiem), to niespotykany i nowatorski sposób na udekorowanie okien. Zaletą tego typu rolet jest ich wielofunkcyjność: osłaniają przed słońcem, zdobią pomieszczenia, pełnią również funkcję reklamową i informacyjną. Mogą być samodzielną dekoracją okna jak również doskonałym uzupełnieniem dla dekoracji już istniejących. W roletach z nadrukiem istnieje możliwość zastosowania tkanin transparentnych oraz zaciemniających. W ofercie: rolety wolnowiszące standard, rolety w kasecie z prowadnicami.',
   },
   {
      id: 'zaluzje vario',
      alt: 'Żaluzje vario',
      header: 'żaluzje vario',
      desc:
         'Żaluzje vario, to połączenie mechanizmu żaluzji plisowanej z żaluzją aluminiową 25 mm. Jest to system, który skutecznie chroni pomieszczenia przed nadmiernym nasłonecznieniem z możliwością sterowania od dołu i od góry zasłaniając dowolny fragment okna. Za pomocą specjalnego pokrętła zamontowanego na górnym profilu, możemy regulować kąty nachylenia lamel według własnych potrzeb.',
   },
   {
      id: 'akcesoria do rolet',
      alt: 'akcesoria do rolet zewnętrznych',
      header: 'akcesoria do rolet zewnętrznych',
      desc:
         'Oferujemy oryginalne, certyfikowane części do rolet zewnętrznych między innymi: silniki przewodowe, silniki radiowe, piloty, przełączniki klawiszowe, przekaźniki do sterowania grupowego, zwijacze, linki, taśmy, wieszaki, zamki baskwilowe, przelotki, bufory, sprężyny, uchwyty linki i taśmy, rączki PCV, koła nawojowe, rury nawojowe. Dostępne akcesoria pochodzą z firm: Aluprof, Somfy, Mobilus Motor, Yooda.',
   },
   {
      id: 'rolety zewnetrzne',
      alt: 'rolety zewnętrzne',
      header: 'rolety zewnętrzne',
      desc:
         'Rolety zewnętrzne zbudowane są z połączonych ze sobą ruchomo cienkich listew z aluminium wypełnionych pianką poliuretanową, dzięki której skutecznie ochronią przed utratą ciepła i uciążliwym hałasem. Dodatkową zaletą rolet zewnętrznych są zabezpieczenia antywłamaniowe. Oferujemy trzy modele rolet: roleta zewnętrzna w systemie Adaptacyjnym (zastosowanie do budynków już istniejących z wykonaną elewacją), roleta zewnętrzna w systemie Integro (zastosowanie do budynków nowo wznoszonych przed wykonaniem elewacji), roleta zewnętrzna w systemie Opoterm (zastosowanie do budynków w trakcie montażu okien). Rolety zewnętrzne mogą być obsługiwane za pomocą napędu elektrycznego lub manualnie.',
   },
   {
      id: 'rolety zewnetrzne screen',
      alt: 'rolety zewnętrzne screen zip',
      header: 'rolety zewnętrzne screen zip',
      desc:
         'Rolety zewnętrzne screen zip, to nowoczesny produkt osłonowy, mocowany na stronie zewnętrznej okna, podobnie jak klasyczne rolety zewnętrzne. Rolety screen, to przede wszystkim ochrona i bariera przed nadmiernym nasłonecznieniem. Zapewniają nie tylko optymalny komfort wizualny i termiczny, ale również działając jako ochrona przed insektami i wiatrem. Rolety screen są odporne na warunki atmosferyczne i praktycznie nie wymagają pielęgnacji, a obsługa dzięki napędowi elektrycznemu jest łatwa i wygodna. Przewagą naszych screenów w porównaniu z innymi osłonami zewnętrznymi jest możliwość przesłonięcia dużych powierzchni szklanych do 6m szerokości i 6m wysokości lub do powierzchni 27 m2 przy zachowaniu niedużej wielkości skrzynki.',
   },
   {
      id: 'zaslony moderno',
      alt: 'zasłony moderno',
      header: 'zasłony moderno',
      desc:
         'Zasłony moderno są nowoczesnym i bardzo praktycznym systemem osłon okiennych, który pełni funkcję firany lub zasłony. Dzięki zastosowaniu specjalnego mechanizmu sterowanego za pomocą sznurka obsługa jest prosta i komfortowa. Tkanina w zasłonach moderno układa się w równe fale, natomiast przy jej złożeniu zajmuje niewiele miejsca. Bogata kolekcja tkanin daje możliwość dopasowania zasłon do każdego rodzaju wnętrza.',
   },
   {
      id: 'panele przesuwne',
      alt: 'zasłony panelowe',
      header: 'zasłony panelowe przesuwne',
      desc:
         'Zasłony panelowe (panel track) zwane inaczej storami japońskimi składają się z pasów tkaninowych poruszających się po wielotorowej szynie. Zabezpieczają przed światłem, promieniami słonecznymi, jak również mogą być użyte jako przegroda pomieszczeń lub dekoracja. Dużym atutem zasłon panelowych jest możliwość przysłaniania dużych powierzchni.',
   },
   {
      id: 'okna pcv',
      alt: 'okna i drzwi pcv',
      header: 'okna PCV',
      desc:
         'Okna PCV wykonane są z wysokogatunkowego profilu niemieckiej marki KBE, który dzięki niezawodności doskonale zabezpiecza przed utratą ciepła w pomieszczeniach. W naszych produktach stosujemy również wysokiej jakości okucia Winkhaus. Oferujemy okna standardowe, trapezowe, trójkątne oraz drzwi tarasowe przesuwne. Na szczególne wyróżnienie zasługuje szeroka gama kolorów i struktur. Dopełnieniem naszej oferty są parapety wewnętrzne plastikowe.',
   },
   {
      id: 'okna drewniane',
      alt: 'okna drewniane i parapety',
      header: 'okna drewniane i parapety',
      desc:
         'Okna drewniane wykonane są z najlepszych gatunków drewna - sosny, merantu i dębu klejonego trójwarstwowo. Doskonałe parametry techniczne, solidne i trwałe profile oraz odpowiednio dobrany lakier  zapewniają długą żywotność i wytrzymałość okna. Odpowiednio dobrane zestawy szybowe skutecznie chronią przed zmianami temperatur oraz tłumią hałas. W naszych oknach stosujemy najwyższej jakości okucia marki Roto. Oferujemy okna standardowe, trapezowe, trójkątne oraz drzwi tarasowe przesuwne. Zajmujemy się również sprzedażą parapetów wewnętrznych drewnianych.',
   },
   {
      id: 'drzwi drewniane',
      alt: 'drzwi drewniane',
      header: 'drzwi drewniane',
      desc:
         'Drzwi drewniane wewnętrzne i zewnętrzne stanowią najbardziej reprezentacyjny element każdego domu. Oferowane przez nas drzwi wykonane są z wysokogatunkowego drewna - sosny, merantu i dębu odpowiednio dobieranego i klejonego warstwowo. Wykonanie drzwi w ten sposób zapewnia trwałość i niezawodność konstrukcji. Realizujemy zróżnicowane rozwiązania według indywidualnych potrzeb związane z rozmiarem, kolorem i kształtem oraz rodzajem materiału z jakiego produkt ma zostać wykonany.',
   },
   {
      id: 'zabudowy balkonow',
      alt: 'zabudowy balkonów',
      header: 'zabudowy balkonów',
      desc:
         'Najbardziej praktycznym systemem zabudowy balkonów jest system COPAL - ramowy i bezramowy. Zabudowy balkonów wpływają korzystnie na utrzymanie stałego poziomu temperatury w każdym pomieszczeniu, ponieważ zabezpieczają przed utratą ciepła. Dodatkowym atutem zabudowy balkonu jest zabezpieczenie przed wiatrem, deszczem, zanieczyszczeniami oraz włamaniem. Systemy COPAL są systemami przesuwnymi, które są wykonane z profili aluminiowych umożliwiających zastosowanie różnego rodzaju szyb. Każdy z paneli szybowych można zdemontować w celu umycia i ponownie zamontować.',
   },
   {
      id: 'bramy garazowe',
      alt: 'bramy garażowe',
      header: 'bramy garażowe',
      desc:
         'Bramy garażowe, to wyjątkowe rozwiązanie techniczne oraz estetyczne spełniające najwyższe wymagania użytkowników. Materiały z których wykonane są bramy charakteryzują się dobrą izolacyjnością cieplną i akustyczną. Trwała konstrukcja daje gwarancję bezpieczeństwa w użytkowaniu. Dzięki panelom wypełnionym pianką poliuretanową pomieszczenie wewnątrz nie przemarza, a dodatkowo tłumi hałas. W naszej ofercie występują trzy rodzaje bram: rolowane, segmentowe, przemysłowe - napęd ręczny lub elektryczny.',
   },
   {
      id: 'markizy',
      alt: 'markizy',
      header: 'markizy',
      desc:
         'Markizy idealnie sprawdzają się w miejscach bardzo nasłonecznionych między innymi: w domach, restauracjach, kawiarniach, hotelach i apartamentach. Markizy dzielimy na trzy modele: bez kasety, w półkasecie, w pełnej kasecie. Obsługa odbywa się manualnie za pomocą korby lub elektrycznie na pilota. Dodatkowo przy sterowaniu elektrycznym markizę można wyposażyć w czujnik wiatrowy, słoneczno - wiatrowy lub wstrząsowy 3D.',
   },
];
