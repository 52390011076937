import React from 'react';
import { FaMapMarkedAlt as MapPin, FaComments as CommentIcon } from 'react-icons/fa';
import { IoMdMailOpen as Mail, IoMdPhonePortrait as Phone } from 'react-icons/io';
import cln from 'classnames';
import VisibilitySensor from '../components/VisibilitySensor';
import * as ga from '../utils/ga';
import SectionHeader from './SectionHeader';
import styles from './Contact.module.css';
import Spinner from './Spinner';

const Contact = () => {
   return (
      <>
         <div className="anchor" id="to-kontakt" />
         <div className={styles.footerContainer}>
            <div className={styles.header}>
               <SectionHeader subHeading="Zapraszamy do kontaktu" />
            </div>

            <div className={styles.contactsContainer}>
               <ul className={styles.contacts}>
                  <h3>Dane kontaktowe</h3>
                  <li>
                     <Phone className={cln(styles.contactsIcon, styles.cellPhoneIcon)} />
                     <a href="tel:504177380" onClick={() => ga.outboundClick('Clicked on phone number')}>
                        504 177 380
                     </a>
                  </li>
                  <li>
                     <Mail className={cln(styles.contactsIcon, styles.mailIcon)} />
                     <a href="mailto:biuro@moskitom.pl" onClick={() => ga.outboundClick('Clicked email address')}>
                        biuro@moskitom.pl
                     </a>
                  </li>
               </ul>
               <div className={styles.hoursContainer}>
                  <h3>Adres firmy</h3>
                  <ul className={styles.hours}>
                     <li>
                        <MapPin className={styles.contactsIcon} />
                        <span>
                           os. Tysiąclecia 31
                           <br />
                           31 - 609 Kraków
                        </span>
                     </li>
                     <li>
                        <CommentIcon className={styles.commentIcon} />
                        <span>Salon ekspozycyjny otwarty po wcześniejszym umówieniu telefonicznym lub mailowym.</span>
                     </li>
                  </ul>
               </div>
            </div>
            <VisibilitySensor scrollCheck scrollDelay={50} intervalCheck={false} offset={{ top: 200 }}>
               {({ isVisible }) => (
                  <div className={styles.map}>
                     {isVisible ? (
                        <iframe
                           title="map"
                           src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2559.7055570546167!2d20.0039658!3d50.0917994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xafae8fe4c0168aec!2sMoskitom!5e0!3m2!1spl!2spl!4v1664998467743!5m2!1spl!2spl"
                           style={{ border: 0, width: '100%', height: '100%' }}
                           allowFullscreen
                           loading="lazy"
                           referrerpolicy="no-referrer-when-downgrade"
                        />
                     ) : (
                        <Spinner />
                     )}
                  </div>
               )}
            </VisibilitySensor>
         </div>
      </>
   );
};

export default Contact;
