import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import Img from 'gatsby-image';
import cols from '../data/collections';
import SectionHeader from './SectionHeader';
import styles from './Collection.module.css';

const cln = classnames.bind(styles);

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Collection = () => {
   const data = useStaticQuery(graphql`
      query {
         allFile(filter: { relativeDirectory: { eq: "collection" } }) {
            nodes {
               childImageSharp {
                  fluid(maxHeight: 1440, quality: 80) {
                     ...GatsbyImageSharpFluid_withWebp
                     originalName
                     presentationWidth
                  }
               }
            }
         }
      }
   `);

   return (
      <>
         <div className="anchor" id="to-produkty" />
         <SectionHeader className={styles.sectionHeader} subHeading="Produkty">
            Zapoznaj się z naszą ofertą
         </SectionHeader>
         {cols.map(({ id, alt, desc, header }, key) => {
            const fluid = data.allFile.nodes.find(
               ({ childImageSharp }) => childImageSharp.fluid.originalName === `${id}.jpg`
            ).childImageSharp.fluid;

            const isOdd = key % 2 === 0;

            return (
               <section key={id} className={cln('colSection', { colSectionRev: isOdd })}>
                  <div
                     className={styles.mobileImage}
                     // data-aos="fade-in"
                     // data-aos-duration={500}
                     // data-aos-easing="ease-in-out"
                  >
                     <Img
                        className={styles.imageWrapper}
                        // style={{ maxHeight: '700px', width: '100%' }}
                        alt={alt}
                        fluid={fluid}
                     />
                  </div>
                  <div className={cln('captionContainer')}>
                     <h1
                     // data-aos="fade-in" data-aos-duration={500} data-aos-delay={200} data-aos-easing="ease-in-out"
                     >
                        {header}
                     </h1>
                     <div
                        className={styles.mobileImageWrapper}
                        // data-aos="fade-in"
                        // data-aos-duration={500}
                        // data-aos-easing="ease-in-out"
                     >
                        <Img
                           className={styles.imageWrapper}
                           // style={{ maxHeight: '700px', width: '100%' }}
                           alt={alt}
                           fluid={fluid}
                        />
                     </div>
                     <p
                     // data-aos="fade-in" data-aos-duration={500} data-aos-delay={300} data-aos-easing="ease-in-out"
                     >
                        {desc}
                     </p>
                  </div>
               </section>
            );
         })}
      </>
   );
};

const NonStretchedImage = props => {
   const { fluid, style } = props;
   let normalizedProps = props;
   if (fluid && fluid.presentationWidth) {
      normalizedProps = {
         ...props,
         style: {
            ...style,
            maxWidth: fluid.presentationWidth,
            margin: '0 auto', // Used to center the image
         },
      };
   }

   return <Img {...normalizedProps} />;
};

NonStretchedImage.propTypes = {
   fluid: PropTypes.object.isRequired,
   style: PropTypes.object,
};

NonStretchedImage.defaultProps = {
   style: {},
};

export default Collection;
