import React from 'react';
import PropTypes from 'prop-types';
import cln from 'classnames';
import css from './SectionHeader.module.css';

const SectionHeader = ({ subHeading, children, className, ...rest }) => {
   return (
      <div className={cln(css.container, className)} {...rest}>
         <h3 className={css.subHeading}>{subHeading}</h3>
         {children && <h2 className={css.heading}>{children}</h2>}
      </div>
   );
};

SectionHeader.propTypes = {
   subHeading: PropTypes.string,
   children: PropTypes.node,
   className: PropTypes.string,
};

SectionHeader.defaultProps = {
   className: undefined,
   subHeading: undefined,
   children: '',
};

export default SectionHeader;
