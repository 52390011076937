/* eslint-disable max-lines-per-function */
import React from 'react';
// import { useStaticQuery, graphql } from 'gatsby';
// import Img from 'gatsby-image';
import Logo from './Logo';
import styles from './MainImage.module.css';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const MainImage = () => {
   // const data = useStaticQuery(graphql`
   //    query {
   //       placeholderImage: file(relativePath: { eq: "main.jpeg" }) {
   //          childImageSharp {
   //             fluid(maxWidth: 3840, quality: 80) {
   //                ...GatsbyImageSharpFluid_withWebp
   //             }
   //          }
   //       }
   //       placeholderImagePortrait: file(relativePath: { eq: "main portrait.jpg" }) {
   //          childImageSharp {
   //             fluid(maxWidth: 1200, quality: 80, srcSetBreakpoints: [320, 532, 698, 841, 970, 1084, 1200]) {
   //                ...GatsbyImageSharpFluid_withWebp
   //             }
   //          }
   //       }
   //    }
   // `);

   return (
      <section className={styles.container}>
         <div className={styles.headerContainer}>
            <div
               data-aos-easing="ease-in-out"
               data-aos="zoom-in"
               data-aos-delay="100"
               data-aos-duration="1200"
               data-aos-once="true"
            >
               <Logo logoOnly className={styles.logo} />
            </div>
            <h1
               className={styles.header}
               data-aos-easing="ease-in-out"
               data-aos="zoom-in"
               data-aos-delay="500"
               data-aos-duration="1200"
            >
               MOSKITOM
            </h1>
            <span
               className={styles.subHeader}
               data-aos-easing="ease-in-out"
               data-aos="fade-up"
               data-aos-delay="700"
               data-aos-duration="1000"
            >
               Komfort domowego zacisza
            </span>
            <span
               data-aos-easing="ease-in-out"
               data-aos="fade-up"
               data-aos-delay="1000"
               data-aos-duration="1000"
               className={styles.block}
            />
         </div>
         <div className={styles.bg} />
         <div
            className="MainImage-module--image--3b1Vo gatsby-image-wrapper"
            style={{ position: 'relative', overflow: 'hidden' }}
         >
            <div style={{ width: '100%', paddingBottom: '66.66666666666667%' }} />
            <picture>
               <source
                  media="(orientation: landscape)"
                  srcSet="data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIDBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABZ82ugQC//8QAGxABAAICAwAAAAAAAAAAAAAAAgEDABEUITL/2gAIAQEAAQUCD2lrkL07ZOKyVC7n/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAgMBAAAAAAAAAAAAAAAAABEBAhBR/9oACAEBAAY/AkyvESPf/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFBYZH/2gAIAQEAAT8h3S+JKQy2Yj+kepSr2LLoTpFk1P/aAAwDAQACAAMAAAAQXO//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBX/8QAGxABAAMBAQEBAAAAAAAAAAAAAQARITFxQbH/2gAIAQEAAT8QBAULbOexAXbhC2/ahLBiv1gYNM75E0cIqy+T6XAVc//Z"
               />
               <source srcSet="data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAYABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQFAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAapaJE0HijL4NJRH/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAIBEQQTFCL/2gAIAQEAAQUCX0ZNqQgjmS1xsiuk2wxLtf8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEQEv/aAAgBAgEBPwE0r//EABwQAAICAgMAAAAAAAAAAAAAAAABETECITIzcf/aAAgBAQAGPwKhRosTkx9Ow4m0pLP/xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMRBBUZHB/9oACAEBAAE/IXre5T7i6i0WvcqErjNdTeFygo+iGSFPE3AdHgH/2gAMAwEAAgADAAAAEE8HQ//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABkRAAEFAAAAAAAAAAAAAAAAAAABEBFBYf/aAAgBAgEBPxCzBSW//8QAHhABAQACAgMBAQAAAAAAAAAAAREAITFBUWGxkaH/2gAIAQEAAT8QJac2dfuLncC+kxTJUHr8MgOalBXfPvKTcjUezxjAOQ4l8wQ3But/jxjSA1Qorl4Vrw6z/9k=" />
               <img
                  src="data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIDBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABZ82ugQC//8QAGxABAAICAwAAAAAAAAAAAAAAAgEDABEUITL/2gAIAQEAAQUCD2lrkL07ZOKyVC7n/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAgMBAAAAAAAAAAAAAAAAABEBAhBR/9oACAEBAAY/AkyvESPf/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFBYZH/2gAIAQEAAT8h3S+JKQy2Yj+kepSr2LLoTpFk1P/aAAwDAQACAAMAAAAQXO//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBX/8QAGxABAAMBAQEBAAAAAAAAAAAAAQARITFxQbH/2gAIAQEAAT8QBAULbOexAXbhC2/ahLBiv1gYNM75E0cIqy+T6XAVc//Z"
                  alt=""
                  style={{
                     position: 'absolute',
                     top: 0,
                     left: 0,
                     width: '100%',
                     height: '100%',
                     objectFit: 'cover',
                     objectPosition: 'center center',
                     opacity: 0,
                     transitionDelay: '500ms',
                  }}
               />
            </picture>
            <picture>
               <source
                  type="image/webp"
                  media="(orientation: landscape)"
                  srcSet="/c3b5d33d9002fd4a859e942909a17cd2/e07ca/main.webp 960w,
/c3b5d33d9002fd4a859e942909a17cd2/e7136/main.webp 1920w,
/c3b5d33d9002fd4a859e942909a17cd2/16b37/main.webp 3840w,
/c3b5d33d9002fd4a859e942909a17cd2/827e6/main.webp 5745w"
                  sizes="(max-width: 3840px) 100vw, 3840px"
               />
               <source
                  media="(orientation: landscape)"
                  srcSet="/c3b5d33d9002fd4a859e942909a17cd2/542be/main.jpg 960w,
/c3b5d33d9002fd4a859e942909a17cd2/f843a/main.jpg 1920w,
/c3b5d33d9002fd4a859e942909a17cd2/87c91/main.jpg 3840w,
/c3b5d33d9002fd4a859e942909a17cd2/48e7c/main.jpg 5745w"
                  sizes="(max-width: 3840px) 100vw, 3840px"
               />
               <source
                  type="image/webp"
                  srcSet="
/85d08a6d3899fe8a1766d31da257304f/32709/main%20portrait.webp 320w,
/85d08a6d3899fe8a1766d31da257304f/49251/main%20portrait.webp 532w,
/85d08a6d3899fe8a1766d31da257304f/6bb07/main%20portrait.webp 698w,
/85d08a6d3899fe8a1766d31da257304f/1d6f4/main%20portrait.webp 841w,
/85d08a6d3899fe8a1766d31da257304f/9884c/main%20portrait.webp 970w,
/85d08a6d3899fe8a1766d31da257304f/7729b/main%20portrait.webp 1084w,
/85d08a6d3899fe8a1766d31da257304f/e7405/main%20portrait.webp 1200w,
/85d08a6d3899fe8a1766d31da257304f/c3bc2/main%20portrait.webp 3177w"
                  sizes="(max-width: 1200px) 100vw, 1200px"
               />
               <source
                  srcSet="
                  /85d08a6d3899fe8a1766d31da257304f/6b877/main%20portrait.jpg 320w,
                  /85d08a6d3899fe8a1766d31da257304f/51e03/main%20portrait.jpg 532w,
                  /85d08a6d3899fe8a1766d31da257304f/ea3b0/main%20portrait.jpg 698w,
                  /85d08a6d3899fe8a1766d31da257304f/b9627/main%20portrait.jpg 841w,
                  /85d08a6d3899fe8a1766d31da257304f/ef597/main%20portrait.jpg 970w,
                  /85d08a6d3899fe8a1766d31da257304f/5a26d/main%20portrait.jpg 1084w,
                  /85d08a6d3899fe8a1766d31da257304f/f65a0/main%20portrait.jpg 1200w,
                  /85d08a6d3899fe8a1766d31da257304f/1e21e/main%20portrait.jpg 3177w"
                  sizes="(max-width: 1200px) 100vw, 1200px"
               />
               <img
                  sizes="(max-width: 3840px) 100vw, 3840px"
                  srcSet="/c3b5d33d9002fd4a859e942909a17cd2/542be/main.jpg 960w,
/c3b5d33d9002fd4a859e942909a17cd2/f843a/main.jpg 1920w,
/c3b5d33d9002fd4a859e942909a17cd2/87c91/main.jpg 3840w,
/c3b5d33d9002fd4a859e942909a17cd2/48e7c/main.jpg 5745w"
                  src="/c3b5d33d9002fd4a859e942909a17cd2/87c91/main.jpg"
                  alt=""
                  loading="lazy"
                  style={{
                     position: 'absolute',
                     top: 0,
                     left: 0,
                     width: '100%',
                     height: '100%',
                     objectFit: 'cover',
                     objectPosition: 'center center',
                     opacity: 1,
                     transition: 'opacity 500ms ease 0s',
                  }}
               />
            </picture>
            <noscript>
               <picture>
                  <source
                     type="image/webp"
                     media="(orientation: landscape)"
                     srcSet="/c3b5d33d9002fd4a859e942909a17cd2/e07ca/main.webp 960w,
/c3b5d33d9002fd4a859e942909a17cd2/e7136/main.webp 1920w,
/c3b5d33d9002fd4a859e942909a17cd2/16b37/main.webp 3840w,
/c3b5d33d9002fd4a859e942909a17cd2/827e6/main.webp 5745w"
                     sizes="(max-width: 3840px) 100vw, 3840px"
                  />
                  <source
                     media="(orientation: landscape)"
                     srcSet="/c3b5d33d9002fd4a859e942909a17cd2/542be/main.jpg 960w,
/c3b5d33d9002fd4a859e942909a17cd2/f843a/main.jpg 1920w,
/c3b5d33d9002fd4a859e942909a17cd2/87c91/main.jpg 3840w,
/c3b5d33d9002fd4a859e942909a17cd2/48e7c/main.jpg 5745w"
                     sizes="(max-width: 3840px) 100vw, 3840px"
                  />
                  <source
                     type="image/webp"
                     srcSet="
/85d08a6d3899fe8a1766d31da257304f/32709/main%20portrait.webp 320w,
/85d08a6d3899fe8a1766d31da257304f/49251/main%20portrait.webp 532w,
/85d08a6d3899fe8a1766d31da257304f/6bb07/main%20portrait.webp 698w,
/85d08a6d3899fe8a1766d31da257304f/1d6f4/main%20portrait.webp 841w,
/85d08a6d3899fe8a1766d31da257304f/9884c/main%20portrait.webp 970w,
/85d08a6d3899fe8a1766d31da257304f/7729b/main%20portrait.webp 1084w,
/85d08a6d3899fe8a1766d31da257304f/e7405/main%20portrait.webp 1200w,
/85d08a6d3899fe8a1766d31da257304f/c3bc2/main%20portrait.webp 3177w"
                     sizes="(max-width: 1200px) 100vw, 1200px"
                  />
                  <source
                     srcSet="/85d08a6d3899fe8a1766d31da257304f/6b877/main%20portrait.jpg 320w,
/85d08a6d3899fe8a1766d31da257304f/51e03/main%20portrait.jpg 532w,
/85d08a6d3899fe8a1766d31da257304f/ea3b0/main%20portrait.jpg 698w,
/85d08a6d3899fe8a1766d31da257304f/b9627/main%20portrait.jpg 841w,
/85d08a6d3899fe8a1766d31da257304f/ef597/main%20portrait.jpg 970w,
/85d08a6d3899fe8a1766d31da257304f/5a26d/main%20portrait.jpg 1084w,
/85d08a6d3899fe8a1766d31da257304f/f65a0/main%20portrait.jpg 1200w,
/85d08a6d3899fe8a1766d31da257304f/1e21e/main%20portrait.jpg 3177w"
                     sizes="(max-width: 1200px) 100vw, 1200px"
                  />
                  <img
                     loading="lazy"
                     sizes="(max-width: 3840px) 100vw, 3840px"
                     srcSet="/c3b5d33d9002fd4a859e942909a17cd2/542be/main.jpg 960w,
/c3b5d33d9002fd4a859e942909a17cd2/f843a/main.jpg 1920w,
/c3b5d33d9002fd4a859e942909a17cd2/87c91/main.jpg 3840w,
/c3b5d33d9002fd4a859e942909a17cd2/48e7c/main.jpg 5745w"
                     src="/c3b5d33d9002fd4a859e942909a17cd2/87c91/main.jpg"
                     alt=""
                     style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        opacity: 1,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                     }}
                  />
               </picture>
            </noscript>
         </div>
      </section>
   );
};

export default MainImage;
