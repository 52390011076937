import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
   TiChartBarOutline as PriceIcon,
   TiArrowRepeatOutline as TimerIcon,
   TiBookmark as CertIcon,
   TiMessage as SupportIcon,
   TiCalendarOutline as CalIcon,
   TiSpannerOutline as ServiceIcon,
} from 'react-icons/ti';
import Img from 'gatsby-image';
import Header from './SectionHeader';
import styles from './AboutUs.module.css';

const ICON_SIZE = 60;

const AboutUs = () => {
   const data = useStaticQuery(graphql`
      query {
         placeholderImage: file(relativePath: { eq: "about.jpeg" }) {
            childImageSharp {
               fluid(maxWidth: 3840) {
                  ...GatsbyImageSharpFluid_withWebp
               }
            }
         }
      }
   `);

   return (
      <>
         <div className="anchor" id="to-o-nas" />
         <section className={styles.aboutUs}>
            <div className={styles.captionContainer}>
               <h1
               // data-aos="fade-in" data-aos-duration={500} data-aos-delay={200} data-aos-easing="ease-in-out"
               >
                  O nas
               </h1>
               <p
               // data-aos="fade-in" data-aos-duration={500} data-aos-delay={300} data-aos-easing="ease-in-out"
               >
                  Firma MOSKITOM oferuje szeroki wybór wysokiej jakości produktów w wersji Top Line (premium): osłony
                  okienne, okna, drzwi, zabudowy balkonów, bramy garażowe i markizy, które opierają się na najnowszych
                  rozwiązaniach technologicznych i estetycznych. Naszym priorytetem jest, aby każdy produkt był
                  dopracowany w najmniejszym szczególe, dlatego przed dostarczeniem go Klientowi przekazywany jest do
                  działu kontroli jakości.
               </p>
            </div>
            <div
            // data-aos="fade-in" data-aos-duration={500} data-aos-easing="ease-in-out"
            >
               <Img fluid={data.placeholderImage.childImageSharp.fluid} />
            </div>
         </section>
         <section className={styles.values}>
            <Header subHeading="Dlaczego powinieneś wybrać nas?">Jesteśmy nowoczesną firmą, którą wyróżniają:</Header>
            <ul>
               {[
                  [PriceIcon, 'ceny producentów'],
                  [CertIcon, 'produkty posiadające certyfikaty'],
                  [TimerIcon, 'długoterminowy okres gwarancyjny'],
                  [SupportIcon, 'fachowe doradztwo i prezentacja produktów'],
                  [CalIcon, 'dogodne terminy realizacji zamówień'],
                  [ServiceIcon, 'serwis gwarancyjny i pogwarancyjny'],
               ].map(([Icon, text], i) => (
                  <li key={text} data-aos="flip-up" data-aos-delay={100 * i}>
                     <span data-aos="fade-in" data-aos-delay={100 * i + 200}>
                        <Icon className={styles.icon} size={ICON_SIZE} />
                     </span>
                     <span>{text}</span>
                  </li>
               ))}
            </ul>
         </section>
      </>
   );
};

export default AboutUs;
